<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Nome" label-for="name">
            <b-form-input
              id="name"
              v-model="name"
              :disabled="!createConsultantEnabled
                && !updateConsultantEnabled"
              :class="{ 'is-invalid': v$.name.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.name.required.$invalid">
                Você deve informar um nome
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-row class="justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'companies-list' }"
              :disabled="saving"
            >
              Cancelar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mx-1"
              :disabled="loading || saving ||
                (!createConsultantEnabled
                  && !updateConsultantEnabled)"
              @click.prevent="save"
            >
              <b-spinner v-if="saving" small />
              <feather-icon v-else icon="SaveIcon" class="mr-50" />
              <span>
                {{ saving ? "Salvando..." : "Salvar" }}
              </span>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { getVueSelectErrorClass } from '@/helpers/validators'
import * as types from '../store/types'
import { CREATE_ACTION, UPDATE_ACTION, DELETE_ACTION, CONSULTANT_RESOURCE } from '@/constants/resources'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() }
  },
  data() {
    return {
      loading: false,
      saving: false,
      name: undefined
    }
  },
  validations() {
    return {
      name: { required }
    }
  },
  computed: {
    ...mapGetters({
      agentTypes: types.CONSULTANT_AGENT_TYPES,
      products: types.PRODUCTS
    }),
    createConsultantEnabled: function() {
      return this.$can(CREATE_ACTION, CONSULTANT_RESOURCE)
    },
    updateConsultantEnabled: function() {
      return this.$can(UPDATE_ACTION, CONSULTANT_RESOURCE)
    },
    deleteConsultantEnabled: function() {
      return this.$can(DELETE_ACTION, CONSULTANT_RESOURCE)
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.loading = true
      this.getConsultant(this.$route.params.id)
        .then(response => {
          const { 
            name 
          } = response.data
          this.name = name
          this.v$.$touch()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar o PN selecionado. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    ...mapActions({
      saveConsultant: types.SAVE_CONSULTANT,
      getConsultant: types.GET_CONSULTANT
    }),
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.saving = true
      const { name } = this
      let payload = {
        id: this.$route.params.id,
        name
      }
      this.saveConsultant(payload)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              text: `O PN foi ${
                this.$route.params.id ? 'atualizado' : 'criado'
              } com sucesso`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'companies-list' })
        })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: error?.response?.data?.message || 'Ocorreu um erro ao salvar o PN. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError)
    },
    addAgent(){
      this.agents.push({ name: '', type: '', email: '', phone: '' })
    },
    dropAgent(){
      if(this.agents.length > 1){
        this.agents.pop()
      }
    }
  },
}
</script>
